import Vue from 'vue';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';
import '@/styles/Styles';
import firebase from './plugins/firebase';

import VueCookie from 'vue-cookie';
Vue.use(VueCookie);

Vue.config.productionTip = false;
// Vue.prototype.$http = axios;

let app: any;
firebase.auth().onAuthStateChanged(() => {
  if(!app) {
    app = new Vue({
      router,
      vuetify,
      render: h => h(App),
    }).$mount('#app');
  }
});