



























import PostReview from '@/components/Post/PostReview.vue'; // @ is an alias to /src
import Complete from '@/components/Post/Complete.vue';
import SetNickname from '@/components/Post/SetNickname.vue';

import axios from 'axios';
import firebase from 'firebase/app';
import 'firebase/auth';

const base_url = process.env.VUE_APP_BASEURL;

export default {
  name: 'Post',
  components: {
    PostReview,
    Complete,
    SetNickname,
  },
  data: () => {
    return {
      complete: false,
      reviewId: '',
      nickname: '',
      sPublicId: '',
      profile: '',
      loading: true,
    }
  },
  async mounted() {
    let publicId: string = this.$route.params.id;
    let token = this.$route.query.firebaseToken || await firebase.auth().currentUser?.getIdToken(true);
    try {
      // check permission
      let ret = (await axios.get(`${base_url}/review/chk-permission/${publicId}`, {
          headers: {
            authorization: `Bearer ${token}`
          }
      })).data;

      if(ret.reviewId !== '') {
        this.$router.replace(`/review/${ret.reviewId}/edit?firebaseToken=${token}`);
        return;
      }

      this.nickname = ret.nickname;
      this.sPublicId = ret.sPublicId;
      this.profile = ret.profile;
    } catch(e) {
      alert('권한이 없습니다!');
      this.$router.replace(`/login/?path=${this.$route.path}`);
    }

    this.loading = false;
  },
  methods: {
    async signOut() {
      await firebase.auth().signOut();
      this.$router.replace(`/login/?path=${this.$route.path}`);
    },
    change(reviewId: string): void {
      this.complete = true;
      this.reviewId = reviewId;
    },
    set(nickname: string): void {
      this.nickname = nickname;
    }
  }
}
