






























import firebase from 'firebase/app';
import 'firebase/auth';
import login from '@/components/Login/loginAPI';

export default {
  name: 'Login',
  components: {
  },
  data() {
    return {
      loading: true,
    }
  },
  async mounted() {
    let path = this.$route.query.path;
    // let idToken = this.$route.query.firebaseToken;
    // if(idToken !== '' && idToken !== undefined) {
    //   try {
    //     await login.reAuth(idToken);
    //     this.$router.replace(path);
    //   } catch(e) {
        
    //   }
    // }
    this.loading = false;
    this.$cookie.set('destPath', path);
  },
  methods: {
    kakaoLogin() {
      // this.loading = true;
      if(process.env.NODE_ENV === 'test') this.$router.replace(`/login/kakao`);
      else
        window.location.href = `https://kauth.kakao.com/oauth/authorize?client_id=${process.env.VUE_APP_KAKAO_REST_API_KEY}&redirect_uri=${process.env.VUE_APP_URL}/login/kakao/&response_type=code`;
    },
    move(): void {
      // console.log('test');
    },
    async signOut() {
      await firebase.auth().signOut();
      this.$router.go(0);
    }
  },
}
