



















import Vue from 'vue';

export default Vue.component('image-slider', {
    props: {
        images: {
            type: Array,
            default: []
        }
    },
    data() {
        return {
            slideStyle: {
                width: '100%',
                height: '414px',
                'background-color': '#F3F3F3',
            },
        }
    },
    methods: {
        getSrc(src: string): string {
            return `${process.env.VUE_APP_REVIEW_IMG}/${src}`
        },
    },
})

