









export default {
    name: 'deprecating',
    created() {
        alert('리뷰를 작성하실 수 없습니다.\n더 나은 모습으로 찾아뵙겠습니다!');
        window.location = 'https://form.twenty.style';
    }
}

