








import Main from '@/styles/Mainstyle';

export default {
  name: 'App',
  components: {
    Main,
  },
  created() {
    
  },
  async mounted() {
  },
};
