import { render, staticRenderFns } from "./PostReview.vue?vue&type=template&id=1f661268&scoped=true&"
import script from "./PostReview.vue?vue&type=script&lang=ts&"
export * from "./PostReview.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1f661268",
  null
  
)

export default component.exports