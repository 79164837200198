











import Vue from 'vue';

export default Vue.component('styled-button', {
    props: [
        'disabled',
    ],
    data: () => {
        return {

        }
    }
});
