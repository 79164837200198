
















import Vue from 'vue';

export default Vue.component('typography', {
    props: [
        'align', 'size', 'color', 'bold', 'ul'
    ],
});
