


















import Vue from 'vue';

export default Vue.component('grid-review', {
    props: [
        'size', 'scale', 'list',
    ],
});
