




















export default {
    props: {
        profile: {
            type: String,
            default: ''
        },
        sPublicId: {
            type: String,
            default: '',
        }
    },
    data() {
        return {
            sellerAvatar: this.profile !== '' ? `${process.env.VUE_APP_IMG}/${this.profile}` : '',
        }
    },
    mounted() {

    },
    methods: {
        next(): void {
            this.$emit('next');
        },
        goToSeller(): void {
            window.location.href = `${process.env.VUE_APP_PROFILE_URL}/${this.sPublicId}`;
        }
    },
}
