import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';

import Post from '@/router/post';
import Review from '@/router/review';
import Login from '@/router/login';

import Home from '@/views/Home.vue';
import NotFound from '@/views/404NotFound.vue';

import Redirect from '@/Redirect.vue';

import firebase from 'firebase/app';
import 'firebase/auth';
import axios from 'axios';
import login from '@/components/Login/loginAPI';

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  Post,
  Review,
  Login,
  {
    path: '/',
    component: Redirect,
    meta: {
      requiresAuth: false,
    }
  },
  {
    path: '*',
    component: NotFound,
  },
  {
    path: '/404-not-found',
    component: NotFound,
  }
]

let router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach(async (to, from, next) => {
  if(to.path !== '/') next('/');
  else next();
  // let requiresAuth = to.matched.some(r => r.meta.requiresAuth);
  // let currentUser = firebase.auth().currentUser;
  // let idToken = to.query.firebaseToken;

  // if(requiresAuth && (idToken === undefined || idToken === '') && !currentUser)
  //   return next({ path: '/login', query: { path: to.path } });
  // else {
  //   return next({});
  // }
});

export default router;
