import Post from '@/views/Post.vue';

const postConfig = {
    path: '/post/:id',
    name: 'Post',
    component: Post,
    meta: {
        requiresAuth: true,
    },
};

export default postConfig;