import Login from '@/views/Login.vue';
import Kakao from '@/components/Login/KakaoLogin.vue'

const LoginConfig = {
    path: '/login',
    name: 'LoginRouter',
    component: Login,
    children: [
        {
            path: 'kakao',
            component: Kakao,
        }
    ]
};

export default LoginConfig;