import Review from '@/views/Review.vue';
import Write from '@/components/WriteReview.vue';
import Get from '@/components/GetReview.vue';
import NotFound from '@/views/404NotFound.vue';
import Complete from '@/components/Post/Complete.vue';

const reviewConfig = {
    path: '/review/:id',
    name: 'ReviewRouter',
    meta: {
        requiresAuth: true,
    },
    component: Review,
    children: [
        {
            path: 'edit',
            component: Write,
        },
        {
            path: 'complete',
            component: Complete,
        }
        // {
        //     path: 'view',
        //     component: Get,
        // },
        // {
        //     path: '*',
        //     component: NotFound
        // }
    ]
};

export default reviewConfig;