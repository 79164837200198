




















import Vue from 'vue';

export default Vue.component('styled-loading', {
    props: [
        'loading',
    ],
});
