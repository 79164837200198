import { render, staticRenderFns } from "./StyledFooter.vue?vue&type=template&id=5b7272b6&"
import script from "./StyledFooter.vue?vue&type=script&lang=ts&"
export * from "./StyledFooter.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports