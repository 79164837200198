import firebase from 'firebase/app';
import 'firebase/auth';
import axios from 'axios';

const auth = async (firebaseToken: string) => {
    await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION);
    await firebase.auth().signInWithCustomToken(firebaseToken);
};

const reAuth = async (idToken: string) => {
    let r = await axios.post(`${process.env.VUE_APP_BASEURL}/auth/kakao/reauth`, {
        idToken,
    }, {
        timeout: 3000,
    });
    await firebase.auth().signOut();
    await auth(r.data);
}

export default {
    auth, reAuth,
}