















import Tempo from '@/components/Post/Tempo.vue';
import WriteReview from '@/components/WriteReview.vue';
import StyledFooter from '@/styles/StyledFooter.vue';

export default {
    name: 'PostReview',
    props: {
        sPublicId: {
            type: String,
            default: '',
        },
        profile: {
            type: String,
            default: '',
        }
    },
    data() {
        return {
            write: false,
        }
    },
    components: {
        Tempo,
        WriteReview,
        StyledFooter,
    },
    async mounted() {
        
    },
    methods: {
        change(): void {
            this.write = !this.write;
        },
        complete(reviewId: string): void {
            this.$emit('complete', reviewId);
        }
    }
}
