


























import Vue from 'vue';

export default Vue.component('input-tag', {
    props: {
        value: {
            type: Array,
            default: () => [],
        },
        max_num: {
            type: Number,
            default: 100,
        },
        max_len: {
            type: Number,
            default: 30
        }
    },
    data() {
        return {
            tags: [...this.value],
            newTag: '',
            rules: [
                (value: string) => {
                    if(value.length > this.max_len) return `태그는 ${this.max_len}자 이내로 해주시길 바랍니다.`;
                    else if(value === '') return true;
                    else return !!value;
                }
            ],
            err: '',
        }
    },
    watch: {
        value() {
            this.tags = [...this.value];
        }
    },
    methods: {
        getMsg(): string {
            switch(this.err) {
            case 'tag-length-overflow':
                return `태그는 ${this.max_len}자 이내로 해주시길 바랍니다.`;
            case 'tags-overflow':
                return `태그는 ${this.max_num}개까지만 사용하실 수 있습니다.`;
            default:
                return '';
            }
        },
        addNewTag(e: any) {
            this.err = '';
            if(this.chkKeyCode(e.keyCode)) {
                let tag = this.newTag;
                let i = 0;
                while(tag[i] === ' ') i++;
                tag = tag.substring(i);
                tag = tag.split('#').find(t => t !== '');
                if(tag === undefined) return;

                this.newTag = '';

                if(tag.length > this.max_len) {
                    this.err = 'tag-length-overflow';
                    return;
                }

                if(this.tags.length >= this.max_num) {
                    this.err = 'tags-overflow';
                    return;
                }

                this.tags.push(tag);
                this.tagChange();
            }
        },
        removeBack() {
            if(this.newTag === '') {
                this.tags.pop();
                this.tagChange();
            }
        },
        chkKeyCode(code: number): boolean {
            switch(code) {
            case 13:   // enter
            case 188:  // comma
                return true;
            default:
                return false;
            }
        },
        parse(s: string) {
            return s;
        },
        remove(i: number): void {
            this.tags.splice(i, 1);
            this.tagChange();
        },
        tagChange(): void {
            this.$emit('input', this.tags);
        }
    }
});
