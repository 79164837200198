import styled from 'vue-styled-components';

const Styles = styled.div`
  background: white;

  header {
    display: flex;
    flex-direction: row;
    width: 1fr;
    height: 61px;
    border-bottom: 1px solid #f3f3f3;
    // padding: 0px 20px;
    // margin-bottom: 16px;
    align-items: center;
    padding: 0px 16px;

    // font-family: Noto Sans KR,sans-serif, Apple Color Emoji , Segoe UI Emoji, Segoe UI Symbol;
    font-weight: 400;
    line-height: 1.5;

    p {
      flex: 1;
    }

    // a {
    //   text-decoration: none;
    //   border-radius: 4px;
    //   padding: 8px 16px;
    //   color: white;
    //   background: #45c295;
    // }

    button {
      max-width: 80px;
    }
  }

  main {
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    padding: 16px;
  }
  
`;

export default Styles;