






















































import Vue from 'vue';
import axios from 'axios';

export default Vue.component('uploader', {
    props: {
        max: {
            type: Number,
            default: 10,
        },
        olds: {
            type: Array,
            default: []
        },
    },
    data() {
        return {
            slideStyle: {
                width: '100%',
                height: '414px',
                'background-color': '#F3F3F3',
            },
            newCovers: Array<String>(),
            oldCovers: [...this.olds],
            files: Array<File>(),
        }
    },
    watch: {
        olds() {
            this.oldCovers = [...this.olds];
        },
    },
    mounted() {
    },
    updated() {
        let i = this.oldCovers.length + this.newCovers.length + 1;
        let query = `#image-upload-slider > div.v-carousel__controls > div > button:nth-child(${i})`;
        let button = document.querySelector(query);
        button.click();
    },
    methods: {
        handleFilesUpload(): void {
            let files = this.$refs.files.files;
            if(this.files.length + files.length > this.max) {
                alert(`10장 이하의 사진만 업로드 하실 수 있습니다.`);
                return;
            }

            for(let file of files) if(this.files.indexOf(file) === -1) {
                this.files.push(file);
                this.newCovers.push(URL.createObjectURL(file));
            }

            this.$emit('updateNew', this.files);
            // this.updateCover();
        },
        // remove(i: number): void {
        //     const obj = this.allCovers[i];
        //     if(obj.newFlag)
        //         this.removeNew(obj.idx);
        //     else this.removeOld(obj.idx);
        //     this.updateCover();
        // },
        removeNew(src: string): void {
            let i = this.newCovers.indexOf(src);
            this.newCovers.splice(i, 1);
            this.files.splice(i, 1);
            this.$emit('updateNew', this.files);
        },
        removeOld(src: string): void {
            let i = this.oldCovers.indexOf(src);
            this.oldCovers.splice(i, 1);
            this.$emit('updateOld', this.oldCovers);
        },
        getImg(src: string): string {
            return `${process.env.VUE_APP_REVIEW_IMG}/${src}`;
        }
    }
});
