var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{style:({
        'text-align': _vm.align,
        'font-size': _vm.size,
        'color': _vm.color,
        'font-weight': (_vm.bold === '' ? 'bold' : 'normal'),
        'text-decoration': (_vm.ul === '' ? 'underline' : ''),
        'margin': '8px 0',
        'font-family': "Noto Sans KR , sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'",
    })},[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }