

















import Vue from 'vue';

export default Vue.component('review-card', {
    name: 'ReviewCard',
    props: [
        'scale', 'src',
    ],
    data() {
        return {
            loading: true,
            cards: '',
            thumbnail: '',
            size: Array<string>(),
        }
    },
    mounted() {
        let cards: string = 'review-card';
        let thumbnail: string = 'thumbnail';
        let size = ['16px', '14px'];
        if(this.scale === 'small') {
            cards += '-small';
            thumbnail += '-small';
            size = ['14px', '12px'];
        }

        this.loading = false;
        this.cards = cards;
        this.thumbnail = thumbnail;
        this.size = size;
    },
    methods: {
        getImgPath(): string {
            return `${process.env.VUE_APP_IMG_URL}/${this.src}`
        },
    },
});
