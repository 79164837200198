









import Vue from 'vue';

let style = `
    align-items: center;
    padding: 8px 0px;
    margin: 0px;
`;

export default Vue.component('styled-row', {
    props: [

    ],
    data: () => {
        return {
            style,
        }
    }
});
