import firebase from 'firebase/app';
import 'firebase/auth';

const firebaseConfig = {
    apiKey: process.env.VUE_APP_APIKEY,
    projectId: process.env.VUE_APP_PROJECT_ID,
    authDomain: process.env.VUE_APP_AUTHDOMAIN
};
firebase.initializeApp(firebaseConfig);

export default firebase;