






export default {
    name: 'NotFound',
    mounted() {
        if(this.$route.path !== '/404-not-found')
            this.$router.replace('/404-not-found');
    }
}
