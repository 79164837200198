





















export default {
    props: {
        reviewId: {
            type: String,
            default: '',
        },
        profile: {
            type: String,
            default: '',
        }
    },
    methods: {
        goToReview(): void {
            window.location.href = `https://app.twenty.style/link/?link=https://review.twenty.style/review/${this.reviewId}&apn=style.twenty.letter&isi=1530896874&ibi=style.twenty.letter`;
        },
        getProfile(): string {
            return `${process.env.VUE_APP_IMG}/${this.profile || this.$route.query.profile}`;
        }
    }
}
