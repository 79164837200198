




























import firebase from 'firebase/app';
import 'firebase/auth';
import axios from 'axios';
import StyledButton from '@/styles/StyledButton.vue';

const base_url = process.env.VUE_APP_BASEURL;

export default {
  components: { StyledButton },
    name: 'SetNickname',
    data() {
        return {
            satisfied: true,
            nickname: '',
            valid: false,
            state: '',
            rules: [
                (value: string) => {
                    if(value === null) return true;
                    if(value.split(' ').length > 1) return '공백 사용 불가';
                    else if(value.length > 20) return '20자 이하만 가능합니다';
                    else return true;
                }
            ],
            dialog: false,
        }
    },
    async mounted() {
        
    },
    methods: {
        setInit() {
            this.state = '';
        },
        setMessages(): string {
            switch(this.state) {
                case 'empty':
                    return '* 닉네임을 입력해주세요.'
                case 'duplicated':
                    return '* 중복된 닉네임입니다.';
                case 'unchecked':
                    return '* 중복 확인이 필요합니다.';
                case 'valid':
                    return '사용 가능한 닉네임입니다.';
                default:
                    return '';
            }
        },
        isValid(): boolean {
            if(this.nickname === null || this.nickname === '' || this.nickname.split(' ').length > 1 || this.nickname.length > 20)
                return false;
            return true;
        },
        async chkValid() {
            let nickname = this.nickname;
            let token = this.$route.query.firebaseToken || await firebase.auth().currentUser?.getIdToken(true);

            try {
                let ret = await axios.post(`${base_url}/user/chk-nickname`, {
                    nickname
                }, {
                    headers: {
                        authorization: `Bearer ${token}`
                    }
                });

                this.state = 'valid';
            } catch(e) {
                if(e.response.data === 'duplicated-nickname')
                    this.state = 'duplicated';
            }
        },
        async submit() {
            if(this.state !== 'valid') {
                if(this.state === '') {
                    if(this.nickname === '') this.state = 'empty';
                    else this.state = 'unchecked';
                }
                (document.getElementById('nickname') as HTMLInputElement).focus();

                return;
            }

            let nickname = this.nickname;
            let token = this.$route.query.firebaseToken || await firebase.auth().currentUser?.getIdToken(true);

            try {
                let ret = await axios.post(`${base_url}/user/set-nickname`, {
                    nickname
                }, {
                    headers: {
                        authorization: `Bearer ${token}`
                    }
                });
                this.$emit('update', nickname);
            } catch(e) {
                alert(e.msg);
                this.$router.go(0);
            }

            return;
        }
    }
};
