






import firebase from 'firebase/app';
import 'firebase/auth';
import login from '@/components/Login/loginAPI'
import axios from 'axios';

const base_url = process.env.VUE_APP_BASEURL;

export default {
    name: 'KakaoLogin',
    props: {
    },
    components: {

    },
    data() {
        return {
            loading: false,
        }
    },
    async mounted() {
        let path = this.$cookie.get('destPath');
            this.$cookie.delete('destPath');
        try {
            this.loading = true;
            await this.kakaoAuth();
            this.loading = false;
            this.$router.replace(`${path}` || '/');
        } catch(e) {
            console.error(e);
            alert('로그인 실패: 다시 시도해주시기 바랍니다');
            this.loading = false;
            this.$router.push(`/login/?path=${path}`);
        }
    },
    methods: {
        async kakaoAuth() {
            if(process.env.NODE_ENV === 'test') {
                let firebaseToken = (await axios.post(`${base_url}/auth/kakao`, {
                    accessToken: 'S1uTp67sN4S4TG5IcWKCz3IGVu-Vn1rmwrHiYworDNIAAAF2aTwS_A'
                }, {})).data;

                await login.auth(firebaseToken);
                return;
            }

            // get kakao code
            let code = this.$route.query.code;
            if(code === undefined) throw new Error('no access code');

            let accessToken = (await axios.post(`https://kauth.kakao.com/oauth/token`, null, {
                params: {
                    grant_type: 'authorization_code',
                    client_id: process.env.VUE_APP_KAKAO_REST_API_KEY,
                    redirect_uri: process.env.VUE_APP_URL + '/login/kakao/',
                    code
                }
            })).data.access_token;

            let firebaseToken = (await axios.post(`${base_url}/auth/kakao`, {
                accessToken
            }, {})).data;

            await login.auth(firebaseToken);
        }
    }
}
