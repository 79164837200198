













import Vue from 'vue';

let style = `
    width: 100%;
    max-width: 414px;
    height: 80px;
    margin: auto;
    display: flex;
    position: fixed;
    bottom: 0%;
`;

export default Vue.component('styled-footer', {
    props: [
        'fixed',
    ],
    data: () => {
        return {
            style,
        }
    }
});
