






















































import firebase from 'firebase/app';
import 'firebase/auth';
import axios from 'axios';

const base_url = process.env.VUE_APP_BASEURL;

export default {
    name: 'WriteReview',
    props: {
        edit: {
            type: Boolean,
            default: false
        },
        sPublicId: {
            type: String,
            default: '',
        },
        profile: {
            type: String,
            default: '',
        }
    },
    components: {

    },
    data() {
        return {
            tags: Array<string>(),
            newCovers: Array<File>(),
            oldCovers: Array<string>(),
            contents: '',
            sellerAvatar: this.profile !== '' ? `${process.env.VUE_APP_IMG}/${this.profile}` : '',
            loading: true,
        };
    },
    async mounted() {
        if(this.edit) {
            let reviewId = this.$route.params.id;
            let token = this.$route.query.firebaseToken || await firebase.auth().currentUser?.getIdToken(true);

            try {
                let review = (await axios.get(`${base_url}/review/get/${reviewId}`, {
                    headers: {
                        authorization: `Bearer ${token}`
                    }
                })).data;

                this.contents = review.contents;
                this.oldCovers = review.cover;
                this.tags = review.tag;
            } catch(e) {
                console.log(e);
                return;
            }
        }
        this.loading = false;
    },
    methods: {
        test() {
            console.log(this.oldCovers);
            console.log(this.newCovers);
        },
        clear(): void {
            // this.contents = '';
            // this.covers = [];
            // this.tags = [];
            this.$emit('close');
            if(this.edit) window.history.back();
        },
        satisfied(): boolean {
            return this.contents.length > 0 && (this.newCovers.length + this.oldCovers.length) > 0 && this.tags.length <= 100;
        },
        setText(): string {
            let s = [
                '사진, 구매 후기를 작성해주세요',
                '사진을 올려주세요',
                '구매 후기를 작성해주세요',
                '저장하기'
            ];
            
            let i = 0;
            if(this.newCovers.length === 0 && this.oldCovers.length == 0) i = 0;
            else i = 2;
            if(this.contents.length > 0) i += 1;

            return s[i];
        },
        async save() {
            this.loading = true;
            if(this.edit) await this.update();
            else await this.submit();
        },
        updateNew(newFiles: Array<File>): void {
            this.newCovers = newFiles;
        },
        updateOld(oldCovers: Array<string>): void {
            this.oldCovers = oldCovers;
        },
        async submit() {
            let formData = new FormData();

            for(let cover of this.newCovers)
                formData.append('cover', cover);

            formData.append('contents', this.contents);
            
            let tags = ['', '', ...this.tags];
            for(let tag of tags)
                formData.append('tag', tag);

            try {
                let token = this.$route.query.firebaseToken || await firebase.auth().currentUser?.getIdToken(true);
                let publicId = this.$route.params.id;
                let reviewId = (await axios.post(`${base_url}/review/insert/${publicId}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': `Bearer ${token}`,
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Headers': '*',
                    },
                    timeout: 100000,
                })).data;

                this.$emit('complete', reviewId);
            } catch(e) {
                alert('전송 실패');
            }
        },
        async update() {
            let formData = new FormData();

            for(let cover of this.newCovers)
                formData.append('newCover', cover);

            formData.append('contents', this.contents);
            
            let tags = ['', '', ...this.tags];
            for(let tag of tags)
                formData.append('tag', tag);

            let oldCovers = ['', '', ...this.oldCovers];
            for(let old of oldCovers)
                formData.append('oldCover', old);

            try {
                let token = this.$route.query.firebaseToken || await firebase.auth().currentUser?.getIdToken(true);
                let reviewId = this.$route.params.id;
                await axios.post(`${base_url}/review/update/${reviewId}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': `Bearer ${token}`,
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Headers': '*',
                    },
                    timeout: 50000
                });

                // window.location.href = `https://app.twenty.style/link/open`;
                this.$router.replace(`/review/${reviewId}/complete?profile=${this.profile}&firebaseToken=${token}`);
            } catch(e) {
                alert(e.response.status, '전송 실패');
            }
        }
    }
}
